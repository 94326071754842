exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-mdx-slug-tsx": () => import("./../../../src/pages/blog/{mdx.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-mdx-slug-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-docs-mdx-slug-tsx": () => import("./../../../src/pages/docs/{mdx.slug}.tsx" /* webpackChunkName: "component---src-pages-docs-mdx-slug-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newsroom-tsx": () => import("./../../../src/pages/newsroom.tsx" /* webpackChunkName: "component---src-pages-newsroom-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

