module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/docuzen/docuzen/frontend/brochure/node_modules/gatsby-remark-prismjs","id":"58a108b4-3219-5da7-a784-9ee908c63922","name":"gatsby-remark-prismjs","version":"6.11.0","modulePath":"/home/runner/work/docuzen/docuzen/frontend/brochure/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[],"showLineNumbers":true},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/runner/work/docuzen/docuzen/frontend/brochure/node_modules/gatsby-remark-autolink-headers","id":"84411983-3572-54e6-8782-dedc5abaf1a7","name":"gatsby-remark-autolink-headers","version":"5.12.1","modulePath":"/home/runner/work/docuzen/docuzen/frontend/brochure/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]},{"resolve":"/home/runner/work/docuzen/docuzen/frontend/brochure/node_modules/gatsby-remark-code-buttons","id":"2e06fcca-7a20-5b7b-a3de-0ed5699d74af","name":"gatsby-remark-code-buttons","version":"2.0.7","modulePath":"/home/runner/work/docuzen/docuzen/frontend/brochure/node_modules/gatsby-remark-code-buttons/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onClientEntry"],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/docuzen/docuzen/frontend/brochure","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-code-buttons/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#fff","display":"minimal-ui","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"937894094c438016463b51384ed1e6d7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-LKB9LVYBRL","AW-10851202604"],"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"21832176","respectDNT":true,"productionOnly":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.docuzen.co/"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
